import { StyledNavbar } from "./Navbar.styles";

const Navbar = () => {
    return (
        <StyledNavbar>
            <img src="/images/mdlmarinaslogo.png" alt="MDL Marina Logo"/>
        </StyledNavbar>
    )
}

export default Navbar;