import styled from "styled-components"

export const StyledCardContainer = styled.div`
    margin-top: 8rem;
    margin-bottom: 5rem;
    width: 100vw;
    padding: 6rem 0 4rem 0;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, max-content));
    grid-gap: 1rem;
    row-gap: 12rem;
    justify-content: space-evenly;
    padding: initial;

    @media (min-width: 2000px) {
     padding: 6rem 0 4rem 0;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-evenly;
     column-gap: 1rem;
    }
`