import React from "react";
import Layout from "../Components/Layout/Layout";
import GlobalStyles from "./global.styles";

const App = () =>
  <>
    <GlobalStyles />
    <Layout />
  </>

export default App;
