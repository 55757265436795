import { StyledCards } from "./Cards.styles";

const Cards = (props) => {
    return (
        <StyledCards href={`http://mdl.marinasystem.intelletech.co.uk/index.html?marina=${props.href}`} target="_blank" onClick={props.onClick}>
                <div className="marina">
                    <div className="marinaImage">
                    <img src={`/images/${props.image}`} alt={`${props.marina} Marina`}/>
                    </div>
                    <p>{props.marina}</p>
                </div>
        </StyledCards>
    )
}

export default Cards