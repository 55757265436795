import { StyledLayout } from "./Layout.styles";
import Navbar from "../Navbar/Navbar";
import CardContainer from "../CardContainer/CardContainer";

const Layout = () => {
    return (
        <StyledLayout>
            <Navbar />
            <CardContainer />
        </StyledLayout>
    )
}

export default Layout;