import { StyledCardContainer } from "./CardContainer.styles";
import Cards from "../Cards/Cards";
import React, { useEffect, useState } from "react";
import { urlAPI } from "../../globalSettings"

const CardContainer = () => {
    const [marinas, setMarinas] = useState([]);

    const sendLogData = async (id, ip) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ marina_id: id, ip_address: ip})
        };
        try {
            await fetch(urlAPI + "/api/log", requestOptions);
        } catch (error) {
            console.log("Oops... something went wrong.");
        }
    }

    const myOnClick = async (id) => {
        try {
            const response = await fetch("https://api.ipgeolocation.io/getip");
            const data = await response.json();
            sendLogData(id, data.ip);
        } catch (error) {
            console.log("Oops... something went wrong.");
        }
    }

    const getMarinas = async () => {
        try {
            const response = await fetch(urlAPI + "/api/marinas");
            setMarinas(await response.json());
        } catch (error) {
            console.log("Oops... something went wrong.");
        }
    }
    
    const renderMarinaCards = () => {
        return(
            marinas.map(marina => (
                <Cards key={marina.id} marina={marina.title} image={marina.image} href={marina.href} onClick={() => myOnClick(marina.id)}/>))
        )
    }

    useEffect(() => {
        getMarinas();
    }, [])

    return (
        <StyledCardContainer>
            {renderMarinaCards()}
        </StyledCardContainer>
    )
}

export default CardContainer