import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    html {
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;
    }

    html, body {
        font-family: sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        overflow: auto;
    }
`

export default GlobalStyles;