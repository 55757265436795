import styled from "styled-components"

export const StyledCards = styled.a`
  height: 15rem;
  width: 25rem;
  /* width: 20%; */

  text-decoration: none;
  color: black;

.marina {
  position: relative;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  transition: all 0.3s;
}

.marinaImage {
  position: absolute;
  height: 70%;
  width: 75%;
  box-shadow: 4px 13px 30px 1px black;
  border-radius: 20px;
  transform: translateY(-80px);
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.marinaImage > img {
  height: 400px;
}

.marina > p {
  position: absolute;
  text-align: center;
  line-height: 4rem;
  bottom: -1.5rem;
  font-size: 2.5rem;
  letter-spacing: 0.2em;
  text-decoration: underline;
  text-underline-position: under;
  text-underline-offset: 0.2em;
}

@media (min-width: 2000px) {
    width: 20%;
    }
`